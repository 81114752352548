import { useFormik } from "formik";
import { Button } from "src/components/Button";
import { FormInput } from "src/components/inputs";
import * as Yup from "yup";
import {
  ErrorNotification,
  handleNotification,
  SuccessNotification,
} from "src/helpers/helperFunction";
import {
  useValidateOtpMutation,
  useResendOTPMutation,
} from "src/api/authApiSlice";
import { ChangeEvent } from "react";
import AuthWrapper from "src/components/AuthWrapper";
import { useNavigate } from "react-router-dom";
import { APP_ROUTE } from "src/helpers/Constant";

// Validation schema for OTP
const otpValidation = Yup.object().shape({
  email: Yup.string().label("Email").email().required("Email is required"),
  otp: Yup.string().label("OTP").required("OTP is required"),
});

type OtpValidationType = Yup.InferType<typeof otpValidation>;

const VerifyEmail = () => {
  const navigate = useNavigate();
  const [validateOtp, { isLoading: isValidating }] = useValidateOtpMutation();
  const [resendOtp, { isLoading: isResending }] = useResendOTPMutation();

  const handleRequest = async (values: OtpValidationType) => {
    try {
      const res: any = await validateOtp({ otp: values.otp });
      if (res.error) {
        return handleNotification(res.error);
      }
      SuccessNotification("OTP validated successfully!");
      navigate(APP_ROUTE.LOGIN);
    } catch (error: any) {
      ErrorNotification(error.message || "Error validating OTP");
    }
  };

  const Formik = useFormik<OtpValidationType>({
    initialValues: {
      email: "",
      otp: "",
    },
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: otpValidation,
    onSubmit: (values) => {
      handleRequest(values);
    },
  });

  const handleResendOtp = async () => {
    try {
      const res: any = await resendOtp({ email: Formik.values.email });
      if (res.error) {
        return handleNotification(res.error);
        if (res.error.message === "") {
          return navigate("otp-verification");
        }
      }
      SuccessNotification("OTP resent successfully!");
    } catch (error: any) {
      ErrorNotification(error.message || "Error resending OTP");
    }
  };

  return (
    <AuthWrapper name="Verify Your Email">
      <form
        onSubmit={Formik.handleSubmit}
        className="w-full flex flex-col justify-around items-center  h-[23rem]"
      >
        <div className="w-full justify-center items-center flex flex-col gap-4">
          <FormInput
            width="70%"
            id="email"
            name="Email"
            labelStyles="block mb-[6px] text-black text-start font-normal text-[17px] text-gray-700"
            type="text"
            onChange={Formik.handleChange}
            value={Formik.values.email}
            onBlur={Formik.handleBlur}
            disabled={isValidating || isResending}
            error={Formik.errors.email}
            touched={Formik.touched.email}
          />

          <FormInput
            width="70%"
            id="otp"
            name="OTP"
            labelStyles="block mb-[6px] text-black text-start font-normal text-[17px] text-gray-700"
            type="text"
            onChange={Formik.handleChange}
            value={Formik.values.otp}
            onBlur={Formik.handleBlur}
            disabled={isValidating}
            error={Formik.errors.otp}
            touched={Formik.touched.otp}
          />
        </div>

        <div className="w-[70%] mt-2 flex flex-col gap-2">
          <Button
            text="Validate OTP"
            disabled={isValidating}
            showModal={isValidating}
            className="h-[40px] font-bold text-white rounded-lg w-full hover:bg-[#104a91] bg-[#002E66]"
            type="submit"
          />

          <Button
            text="Resend OTP"
            type="button"
            onClick={handleResendOtp}
            disabled={isResending}
            showModal={isResending}
            className="h-[40px] font-bold text-white rounded-lg w-full hover:bg-[#104a91] bg-[#002E66]"
          />
        </div>
      </form>
    </AuthWrapper>
  );
};

export default VerifyEmail;
