import { IconButton } from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";
import { Button } from "src/components/Button";
import { FormModal } from "src/components/ModalComp";
import { SearchInput, SelectInputNew } from "src/components/inputs";
import { CurrencyFormatter, truncateString } from "src/helpers/helperFunction";
import { useAuth } from "src/hooks/useAuth";
import {
  useGetAllHQTransactionsQuery,
  useHqWalletQuery,
} from "src/hq-admin/hq-api/hqTransactionApiSlice";
import { useFetchHQBranchQuery } from "src/hq-admin/hq-api/manageHqApiSlice";
import Transaction from "../Transaction";
import { cardBtnType, TransactionsType } from "src/helpers/alias";
import { format } from "date-fns";
import { HeadCellTypes } from "../Manage-branch";
import { LoaderContainer } from "src/components/LoaderContainer";
import { useDebounce } from "src/hooks/useDebounce";
import { SelectInput } from "src/components/SelectInput";
import {
  CATEGORIES,
  SOURCES,
  STATUS,
  TYPES,
  filterData,
} from "src/helpers/data";
import { FaArrowRotateRight, FaEye, FaEyeSlash } from "react-icons/fa6";
import FundWalletModal from "./components/FundWalletModal";
import { WithDrawalMoal } from "./components/WithdrawalModal";
import { Badge } from "src/components/Badge";
import walletBtn from "src/assets/img/walletbtn.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import { CardButton } from "src/components/Card";
import { enUS } from "date-fns/locale";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";

const transactionTabData: cardBtnType[] = [
  {
    id: 2,
    icon: walletBtn,
    name: "Hq Transactions",
  },
  {
    id: 3,
    icon: Attendant,
    name: "Branch Transactions",
  },
];

const brachesHeadCells: readonly HeadCellTypes[] = [
  {
    id: "created",
    minWidth: 210,
    label: "Transaction date",
  },
  {
    id: "accountName",
    minWidth: 170,
    label: "Name",
  },

  {
    id: "amount",
    minWidth: 170,
    label: "Transaction Amount (N)",
  },
  // {
  //   id: "referenceId",
  //   minWidth: 170,
  //   label: "Reference",
  // },
  {
    id: "category",
    minWidth: 210,
    label: "Category",
  },
  {
    id: "type",
    minWidth: 170,
    label: "Type",
  },

  {
    id: "status",
    minWidth: 170,
    label: "Status",
  },
];

const hqHeadCells: readonly HeadCellTypes[] = [
  {
    id: "created",
    minWidth: 210,
    label: "Transaction date",
  },
  {
    id: "accountName",
    minWidth: 170,
    label: "Name",
  },

  {
    id: "amount",
    minWidth: 170,
    label: "Transaction Amount (N)",
  },
  // {
  //   id: "referenceId",
  //   minWidth: 170,
  //   label: "Reference",
  // },
  {
    id: "category",
    minWidth: 210,
    label: "Category",
  },
  {
    id: "type",
    minWidth: 170,
    label: "Type",
  },

  {
    id: "status",
    minWidth: 170,
    label: "Status",
  },
  // {
  // 	id: "walletId",
  // 	minWidth: 170,
  // 	label: "Wallet ID",
  // },

  // {
  // 	id: "reason",
  // 	minWidth: 170,
  // 	label: "Reason",
  // },
];

export default function Wallet() {
  const { user } = useAuth();
  const [cardName, setCardName] = useState<any>({
    tab: "hq transactions",
    who: "station_hq",
    for: "station_hq",
  });
  const [showBalance, setShowBalance] = useState(false);
  const [page, setPage] = useState(1);
  const result = useHqWalletQuery({});
  console.log(result);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showFundModal, setFundModal] = useState(false);
  const [filteredValue, setFilteredValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filteredValue, 700);
  const [info, setInfo] = useState({
    source: "",
  });

  const fetchAllBranchResult = useFetchHQBranchQuery({
    orderBy: "createdAt:desc",
  });
  const walletInformation = useHqWalletQuery({});
  const handleTabChange = (newValue: string) => {
    if (newValue === "hq transactions") {
      setCardName({
        tab: "hq transactions",
        who: "station_hq",
        for: "station_hq",
      });
    }
    if (newValue === "branch transactions") {
      setCardName({
        tab: "branch transactions",
        who: "station_branch",
        for: "station_branch",
      });
    }
  };

  const allTransactionsResult = useGetAllHQTransactionsQuery({
    stationHQ: user.stationHQ?.id,
    limit: 8,
    for: cardName.who,
    orderBy: "createdAt:desc",
    populate: "stationBranch,stationHQ",
    search: debouncedValue,
    page,
    ...(STATUS.includes(info?.source) && { status: info.source }),
    ...(SOURCES.includes(info?.source) && { source: info.source }),
    ...(CATEGORIES.includes(info?.source) && { category: info.source }),
    ...(TYPES.includes(info?.source) && { type: info.source }),
  });

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handledTransactionResponse = useMemo(() => {
    const transactions = allTransactionsResult?.data || [];

    return transactions?.data?.reduce(
      (
        acc: { tableData: { previewData: any } }[],
        cur: TransactionsType | any
      ) => {
        console.log(
          `${
            cur.source === "instant_wallet"
              ? cur?.meta?.destinationHolderName || "----------"
              : cur?.type === "credit"
              ? cur?.meta?.recieverName || "----------"
              : cur?.type === "debit"
              ? cur?.meta?.senderName || "----------"
              : ""
          }`
        );
        const tableData: any = {
          created: format(new Date(cur.createdAt), "do MMM yyyy, hh:mm a", {
            locale: enUS,
          }),
          reference: cur?.reference || "----------",
          accountName: `${
            cur.source === "instant_wallet"
              ? cur?.meta?.destinationHolderName || "----------"
              : cur?.type === "credit"
              ? cur?.meta?.recieverName || "----------"
              : cur?.type === "debit"
              ? cur?.meta?.senderName || "----------"
              : ""
          }`,
          amount: CurrencyFormatter(Number(cur?.amount)),
          category: cur?.category ? (
            <Badge
              type={"TRANSACTION_CATEGORY_OPTIONS"}
              status={cur?.category}
            />
          ) : (
            "----------"
          ),
          fee: CurrencyFormatter(cur.meta.fee),
          source: cur?.source ? (
            <Badge type={"TRANSACTION_SOURCES_OPTIONS"} status={cur?.source} />
          ) : (
            "----------"
          ),
          type: cur?.type ? (
            <Badge type={"TRANSACTION_TYPE_OPTIONS"} status={cur?.type} />
          ) : (
            cur?.type || "----------"
          ),
          status: cur?.status ? (
            <Badge type={"TRANSACTION_STATUS_OPTIONS"} status={cur?.status} />
          ) : (
            cur?.status || "----------"
          ),
          ...(cardName.tab === "hq transactions" && {
            stationHQ: cur?.stationHQ?.name || "----------",
          }),
          ...(cardName.tab === "branch transactions" && {
            branchName: cur?.stationBranch?.name || "----------",
          }),

          previewData: {},
        };

        tableData.previewData = {
          created: tableData.created,
          fee: tableData.fee,
          reference: tableData.reference,
          accountName: tableData.accountName,

          amount: tableData.amount,
          category: tableData.category,
          source: tableData.source,
          type: tableData.type,
          status: tableData.status,
          message: cur?.meta?.message || "----------",
          purpose: truncateString(cur?.purpose, 50) || "----------",
          balanceAfterTransaction:
            cur?.balanceAfterTransaction || cur?.balanceAfterTransaction === 0
              ? CurrencyFormatter(cur?.balanceAfterTransaction)
              : "----------",
          // stationBalanceAfterTransaction:
          //   cur?.stationBalanceAfterTransaction ||
          //   cur?.stationBalanceAfterTransaction === 0
          //     ? CurrencyFormatter(cur?.stationBalanceAfterTransaction)
          //     : "----------",
        };

        switch (cur.type) {
          case "debit":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.senderName || "----------",
              receiversName: cur?.meta?.recieverName || "----------",
            };
            break;
          case "credit":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.recieverName || "----------",
              sendersName: cur?.meta?.senderName || "----------",
            };
            break;
          // case "withdrawal":
          //   tableData.previewData = {
          //     ...tableData.previewData,
          //     sendersName: cur?.meta?.senderName || "----------",

          //     sendersSystemCode: cur?.meta?.senderSystemCode || "----------",
          //     bankName: cur?.meta?.bankName || "----------",
          //     receiversName: cur?.meta?.recieverName || "----------",
          //     receiversAccountNumber:
          //       cur?.meta?.receiverAccountNumber || "----------",
          //     accountNumber: cur?.meta?.accountNumber || "----------",
          //     receiversBank: cur?.meta?.recieverName || "----------",
          //   };
          //   break;
          case "referral_bonus":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.destinationHolderName || "----------",
              accountSystemCode: cur?.meta?.accountSystemCode || "----------",
              nameOfPersonReferred:
                cur?.meta?.nameOfPersonReferred || "----------",
              systemCodeOfPersonReferred:
                cur?.meta?.systemCodeOfPersonReferred || "----------",
            };
            break;

          case "premium_feature":
            tableData.previewData = {
              ...tableData.previewData,

              branchSystemCode: cur?.meta?.branchSystemCode || "----------",
              nameOfFeatureAdded: cur?.meta?.nameOfFeatureAdded || "----------",
            };
            break;
          default:
            break;
        }

        switch (cur.category || cur.source) {
          case "fuel_purchase":
            tableData.accountName = cur?.meta?.senderName || "----------";

            tableData.previewData = {
              attendantName: cur?.meta?.senderName || "----------",
              ...tableData.previewData,
            };

            delete tableData.previewData.sendersName;
            delete tableData.previewData.fee;
            delete tableData.previewData.stationBalanceAfterTransaction;

            break;
          case "deposit":
            delete tableData.previewData.fee;

            break;
          case "premium_feature":
            tableData.previewData = {
              ...tableData.previewData,
              branchSystemCode: cur?.meta?.senderSystemCode || "----------",
              nameOfFeatureAdded:
                (cur?.meta?.amenitiesAdded && cur?.meta?.products.join(", ")) ||
                (cur?.meta?.products &&
                  cur?.meta?.products.map((p: any) => p.name).join(", ")),
            };
            delete tableData.previewData.sendersName;

            break;
          case "in_app_transfer":
            if (cur.type === "credit") {
              tableData.accountName = cur?.meta?.senderName || "----------";
            }
            if (cur.type === "debit") {
              tableData.accountName = cur?.meta?.recieverName || "----------";
            }
            tableData.previewData = {
              receiversName: cur?.meta?.recieverName || "----------",
              systemCode: cur?.meta?.systemCode || "----------",
              ...tableData.previewData,
            };
            break;
          case "withdrawal":
            tableData.accountName = cur?.meta?.recieverName || "----------";
            console.log(tableData);

            tableData.previewData = {
              ...tableData.previewData,
              sendersName: cur?.meta?.senderName || "----------",
              ...(cardName.who === "station_hq"
                ? {
                    branchSystemCode:
                      cur?.meta?.senderSystemCode || "----------",
                  }
                : {
                    hqSystemCode: cur?.meta?.senderSystemCode || "----------",
                  }),
              bankName: cur?.meta?.bankName || "----------",
              receiversName: cur?.meta?.recieverName || "----------",
              receiversAccountNumber: cur?.meta?.accountNumber || "----------",
            };
            break;

          case "referral_bonus":
            tableData.previewData = {
              ...tableData.previewData,
              accountName: cur?.meta?.destinationHolderName || "----------",
              accountSystemCode: cur?.meta?.accountSystemCode || "----------",
              nameOfPersonReferred:
                cur?.meta?.nameOfPersonReferred || "----------",
              systemCodeOfPersonReferred:
                cur?.meta?.systemCodeOfPersonReferred || "----------",
            };
            break;
          default:
            break;
        }
        switch (cur.source) {
          case "available_balance":
            if (tableData.previewData.branchSystemCode) {
              delete tableData.previewData.branchSystemCode;
            }

            break;
        }

        console.log(tableData.accountName);
        return [...acc, { ...tableData }];
      },
      []
    );
  }, [allTransactionsResult]);

  const handleSelectChange = (event: { target: { value: string } }) => {
    setInfo((prev) => {
      return { source: event.target.value };
    });
  };

  const handleModal = () => setShowAddModal((prevState) => !prevState);
  const handleFundWalletModal = () => setFundModal((prevState) => !prevState);
  return (
    <LoaderContainer
      data={result || allTransactionsResult || walletInformation}
    >
      <section>
        <article className="min-w-screen h-full ">
          <div
            className="min-w-[530px] max-w-[530px] h-[14rem] bg-[#002E66] text-white  rounded-lg flex flex-col  gap-y-3
                 font-bold text-lg  p-6"
          >
            <h4 className="text-left">{user?.stationHQ?.name}</h4>
            <h4 className="text-left text-sm">{user?.stationHQ?.systemCode}</h4>

            <div className="flex items-center justify-between pr-4">
              <div className=" flex gap-4">
                <div>
                  <h2 className="text-sm text-left">Available Balance</h2>
                  <h3 className="font-bold text-2xl">
                    {showBalance
                      ? CurrencyFormatter(result?.data?.availableBalance)
                      : "*******"}
                  </h3>
                </div>
                <div>
                  <h2 className="text-sm text-left">Branch Balance</h2>
                  <h3 className="font-bold text-2xl">
                    {showBalance
                      ? CurrencyFormatter(result?.data?.branchesBalance)
                      : "*******"}
                  </h3>
                </div>
                <div>
                  <h2 className="text-sm text-left">Offline Sales Balance</h2>
                  <h3 className="font-bold text-2xl">
                    {showBalance
                      ? CurrencyFormatter(result?.data?.offlineSalesBalance)
                      : "*******"}
                  </h3>
                </div>
              </div>
              <div>
                <IconButton
                  onClick={() => {
                    setShowBalance((prevState) => !prevState);
                  }}
                >
                  {showBalance ? (
                    <FaEyeSlash className="text-white" />
                  ) : (
                    <FaEye className="text-white" />
                  )}
                </IconButton>
              </div>
            </div>
            <div className="flex justify-start gap-2 items-center">
              <Button
                text="Withdraw"
                type="button"
                className=" font-bold text-sm text-[#002E66] w-[40%] rounded-lg px-2 py-3 hover: bg-white flex items-center justify-center"
                onClick={handleModal}
              />
              <Button
                text="Fund wallet"
                type="button"
                className=" font-bold text-sm  text-[#002E66] rounded-lg px-1 py-3 hover: bg-white flex items-center justify-center"
                onClick={handleFundWalletModal}
              />
              <div
                className="font-bold cursor-pointer flex text-sm text-[#002E66] rounded-lg px-4 py-[15px] hover: bg-white  items-center justify-center"
                onClick={() => result.refetch()}
                title="Refresh Balance"
              >
                <FaArrowRotateRight className="text-002E66" />
              </div>
            </div>
          </div>
          <div className="w-full overflow-auto">
            <div className="flex w-full py-3 justify-between items-center">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 py-1">
                {transactionTabData.map((dt) => (
                  <Fragment key={dt.id}>
                    <CardButton
                      name={dt.name}
                      icon={dt.icon}
                      link={dt.link}
                      height={"60px"}
                      activeBtn={cardName.tab}
                      onClick={() => {
                        handleTabChange(dt.name?.toLowerCase());
                      }}
                    />
                  </Fragment>
                ))}
              </div>
              <div className="flex flex-wrap justify-end gap-3 items-center my-2 h-20">
                <SearchInput
                  name="branch-search"
                  placeholder="Search for reference"
                  value={filteredValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const target = e.target;
                    setFilteredValue(target.value);
                  }}
                />
                <SelectInputNew
                  iniText="Select category"
                  tabData={filterData}
                  filteredValue={info.source}
                  onChange={handleSelectChange}
                />
              </div>
            </div>
            <Transaction
              headCells={
                cardName.tab === "branch transactions"
                  ? brachesHeadCells
                  : hqHeadCells
              }
              transactions={handledTransactionResponse}
              transactionResult={allTransactionsResult}
              handleChangePage={handleChangePage}
            />
          </div>
        </article>
        {showFundModal ? (
          <FormModal name="Fund wallet" onClick={handleFundWalletModal}>
            <FundWalletModal
              data={walletInformation?.data}
              closeModal={handleFundWalletModal}
            />
          </FormModal>
        ) : null}

        {showAddModal ? (
          <FormModal name="Withdraw" onClick={handleModal}>
            <WithDrawalMoal closeModal={handleModal} />
          </FormModal>
        ) : null}
      </section>
    </LoaderContainer>
  );
}
