import React, { useMemo, useState } from "react";

import { TableLoader } from "src/components/LoaderContainer";
import { FormModal } from "src/components/ModalComp";
import EnhancedTable from "src/components/Table";
import { forEnums, handleDateFormat } from "src/helpers/helperFunction";
import useHandleRowClick from "src/hooks/useHandleRowClick";
import useHandleSelectAllClick from "src/hooks/useHandleSelectAllClick";
import useHandleSingleSelect from "src/hooks/useHandleSingleSelect";
import useIsSelected from "src/hooks/useIsSelected";
import HQ from "src/assets/img/Attendanticon.svg";
import { cardBtnType } from "src/helpers/alias";
import { CardButton } from "src/components/Card";
import { useFetchAllHQNotificationQuery } from "src/hq-admin/hq-api/notificationApiSlice";
import { useAuth } from "src/hooks/useAuth";
import { Tabs, Tab } from "@mui/material";
import { useFetchHQBranchQuery } from "src/hq-admin/hq-api/manageHqApiSlice";
import { SendNotificationModal } from "./components/SendNotifiction";
import BranchesNotification from "./components/BranchesNotification";
import { useDebounce } from "src/hooks/useDebounce";
import { SearchInput } from "src/components/inputs";
import walletBtn from "src/assets/img/walletbtn.svg";
import Attendant from "src/assets/img/Attendanticon.svg";
import { hqTransactionSlice } from "src/hq-admin/hq-api/hqTransactionApiSlice";

const HQData: cardBtnType[] = [
  {
    id: 2,
    icon: HQ,
    name: "Send to Branch",
  },
  {
    id: 2,
    icon: walletBtn,
    name: "Hq Notifications",
  },
  {
    id: 3,
    icon: Attendant,
    name: "sent Notifications",
  },
];

const transactionTabData: cardBtnType[] = [];
const headCells: readonly any[] = [
  {
    id: "title",
    minWidth: 170,
    label: "Title",
  },
  {
    id: "message",
    minWidth: 170,
    label: "Message",
  },
  {
    id: "for",
    minWidth: 170,
    label: "For",
  },
  {
    id: "createdAt",
    minWidth: 170,
    label: "Date",
  },
];

export default function Notification() {
  const { user } = useAuth();

  const [cardName] = useState("");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);

  const { handleSelectAllClick, selected, setSelected } =
    useHandleSelectAllClick([]);
  const [page, setPagination] = useState(1);
  const { isSelected } = useIsSelected(selected);
  const { handleClick } = useHandleSingleSelect(selected, setSelected);
  const { handleRowClick } = useHandleRowClick();
  const [value, setValue] = useState(0);

  const [currentTab, setCurrentTab] = useState("sent notifications");
  const [filteredValue, setFilteredValue] = useState<string>("");
  const { debouncedValue } = useDebounce(filteredValue, 700);

  const SentNotificationResult = useFetchAllHQNotificationQuery({
    sentBy: user?.stationHQ?.admin,
    ...(debouncedValue && { search: debouncedValue }),
    page,
  });

  const HqnotificationResult = useFetchAllHQNotificationQuery({
    ...(debouncedValue && { search: debouncedValue }),
    for: "stationHq",
    page,
  });
  // const notificationResult = useFetchAllNotificationQuery({
  // 	page: page.newPage,
  // });

  // API TO GET ALL HQ INFORMATION

  // console.log(notificationResult);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPagination((prev) => newPage);
  };

  const BranchhandledAPIResponse = useMemo(() => {
    const hqProfile = SentNotificationResult?.currentData?.data || [];

    return hqProfile?.data?.reduce(
      (
        acc: { [index: string]: string }[],
        cur: {
          [index: string]: string;
        }
      ) => [
        ...acc,
        {
          id: cur?.id,
          title: cur?.title,
          message: cur?.message,
          for: forEnums[cur?.for],
          createdAt: handleDateFormat(cur?.createdAt),
        },
      ],
      []
    );
  }, [SentNotificationResult]);

  const HqhandledAPIResponse = useMemo(() => {
    const hqProfile = HqnotificationResult?.currentData?.data || [];

    return hqProfile?.data?.reduce(
      (
        acc: { [index: string]: string }[],
        cur: {
          [index: string]: string;
        }
      ) => [
        ...acc,
        {
          id: cur?.id,
          title: cur?.title,
          message: cur?.message,
          for: forEnums[cur?.for],
          createdAt: handleDateFormat(cur?.createdAt),
        },
      ],
      []
    );
  }, [HqnotificationResult]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchAllBranchResult = useFetchHQBranchQuery({});

  return (
    <section>
      <article>
        <div className="flex justify-between gap-2 items-start">
          {SentNotificationResult?.isSuccess ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4  pb-3">
              {HQData.map((dt) => (
                <CardButton
                  name={dt.name}
                  icon={dt.icon}
                  link={dt.link}
                  height={"60px"}
                  activeBtn={currentTab}
                  onClick={() => {
                    // switch (dt.name.toLowerCase()) {
                    //   case "Send to Branch":
                    //     setShowAddModal(true);
                    //     break;
                    //   default:
                    //     break;
                    // }
                    setCurrentTab(dt.name.toLowerCase());
                  }}
                />
              ))}
            </div>
          ) : null}
          <SearchInput
            name="branch-search"
            placeholder="Search "
            value={filteredValue}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const target = e.target;
              setFilteredValue(target.value);
            }}
          />
        </div>
        {currentTab === "sent notifications" ? (
          <BranchesNotification
            notificationResult={SentNotificationResult}
            handledAPIResponse={BranchhandledAPIResponse}
            setPagination={setPagination}
          />
        ) : (
          ""
        )}
        {currentTab === "hq notifications" ? (
          <BranchesNotification
            notificationResult={HqnotificationResult}
            handledAPIResponse={HqhandledAPIResponse}
            setPagination={setPagination}
          />
        ) : (
          ""
        )}

        {currentTab === "send to branch" ? (
          <FormModal
            name="Send to Branch"
            onClick={() => setCurrentTab("sent notifications")}
          >
            <SendNotificationModal
              name={cardName}
              close={() => setCurrentTab("sent notifications")}
              data={fetchAllBranchResult?.data?.data}
            />
          </FormModal>
        ) : null}
      </article>
    </section>
  );
}
